import * as React from 'react'
import styled from 'styled-components'
import { MastheadNotFound } from '@components'
import { graphql, Link } from 'gatsby'

interface PageProps {
  data: Queries.NotFoundPageQuery
}

const NotFound = ({ data: { sanityPage } }: PageProps) => {
  if (!sanityPage) return
  return (
    <>
      <ArticleHeader>
        <WideSiteTitle>
          404 <Small>try another channel</Small>
        </WideSiteTitle>
      </ArticleHeader>
      <MastheadNotFound images={sanityPage.stills?.map(still => still?.asset?.url || '') || []} />
      <StyledLink to="/" />
    </>
  )
}

const StyledLink = styled(props => <Link {...props} />)`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
`

const ArticleHeader = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 20;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 75px;
  transform: none;
  font-size: 6vw;
  @media only screen and (min-width: 744px) {
    height: 100px;
  }
  @media only screen and (min-width: 1280px) {
    font-size: 50px;
  }
  @media (max-aspect-ratio: 137/100) {
    pointer-events: none;
  }
`

const WideSiteTitle = styled.h1`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;

  text-align: center;
  font-family: corsair;
  cursor: pointer;

  background: black;
  color: white;
`

const Small = styled.div`
  font-size: 0.6em;
  color: #ff0000;
  padding: 0 0.35em;
`

export const query = graphql`
  query NotFoundPage {
    sanityPage(slug: { current: { eq: "404" } }) {
      title
      copy {
        title
        _rawCopy
      }
      stills {
        asset {
          url
        }
      }
    }
  }
`

export default NotFound
